.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
    margin-bottom: 30px;
}

.project-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transition: all 0.3s;
}
.home-page-container {
    position: relative;
}

.home-page-container .welcome-to-my-world {
    min-height: 250px;
    background: black;
}

.home-page-container .introduction {
    min-height: 500px;
    background: #191919;
}

.home-page-container .chakra-tabs__tablist {
    border-bottom: none;
}

.home-page-container .css-1ltezim[aria-selected="true"] {
    color: #f9004d;
}

.home-page-container svg {
    height: 50px;
    width: 50px;
}
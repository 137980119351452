.notfound-container {
  display: flex;
  justify-content: center;
}

.notfound-content {
  text-align: center;
  padding-top: 100px;
  width: 400px;
  height: 400px;
}

.notfound-logo {
  margin: 0 auto;
}

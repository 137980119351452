@font-face {
    font-family: "Avenir";
    src: url("../src//fonts/AvenirRoman.otf");
}

@font-face {
    font-family: "Helvetica";
    src: url("../src/fonts/Helvetica.ttf");
}

@font-face {
    font-family: "SansSerifFLF";
    src: url("../src//fonts/SansSerifFLF.otf");
}

body {
    font-family: "Avenir", "Helvetica", "SansSerifFLF" !important;
}

@media only screen and (max-width: 600px) {
    iframe {
        height: 300px !important;
        width: 100% !important;
    }
}

@media only screen and (min-width: 600px) {
    iframe {
        height: 400px !important;
        width: 500px !important;
    }
}

@media only screen and (min-width: 768px) {
    iframe {
        height: 420px !important;
        width: 600px !important;
    }
}

@media only screen and (min-width: 992px) {
    iframe {
        height: 450px !important;
        width: 650px !important;
    }
}

@media only screen and (min-width: 1200px) {
    iframe {
        height: 450px !important;
        width: 700px !important;
    }
}

.css-wx2kyh {
    box-shadow: none !important;
    border: 1px solid #718096;
}
